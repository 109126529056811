:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  --dkClr: #070730;
  --liClr: whiteSmoke;
  --accColor: #2776a1;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  background-color: #000000;
  color: var(--liClr);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header,
footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
}

main {
  flex: 1;
  display: flex;
}

#gameCanvas {
  position: absolute;
  top: 0%;
  left: 0%;
  transform: translate (0, 0);
  display: flex;
  z-index: 1;
}

.gameUI {
  width: 100%;
  height: 100%;
  background-color: var(--accColor);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.gameUI * {
  text-align: center;
  margin-top: 5px;
}

#gameStartDiv {
  display: flex;
}

#gameEndDiv {
  display: none;
}

#startButton {
  display: inline-block;
  width: 500px;
  padding: 8px 15px;
}


#startButton p {
  margin-top: 10px;
}

#startButton hover {
  background-color: var(--dkClr);
  cursor: pointer;
}